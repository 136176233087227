import request from '@/utils/request';


// 企业微信配置
export function getEnWechatConfig(params) {
    return request({ url: `ext/weixin/config/`, method: 'get', params })
}


// 企业微信应用配置
export function getEnWechatAgentConfig(params) {
    return request({ url: `/ext/weixin/agentconfig/`, method: 'get', params })
}


// 获取erp数据,通过api名称决定请求那一个后端接口
export function getExternalApprovalData(params) {
    const { api_name, ...otherParams } = params;
    const url = `/approval/${api_name}/`;

    return request({ url, method: 'get', params: otherParams });
}
