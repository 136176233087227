<template>
  <div>
    <a-row>
      <a-col :xs="24" :sm="12" :md="8" :lg="6" :xl="4">
        <a-space>
          <a-input v-model="params.search" placeholder="查询名称" allowClear @clear="handleClear" @pressEnter="search"/>
          <a-button type="primary" icon="search" @click="search">查询</a-button>
        </a-space>
      </a-col>
    </a-row>

    <a-table :row-selection="rowSelection" :columns="columns" :data-source="tableData"
             :pagination="pagination" @change="handleTableChange"  :loading="loading">
    </a-table>
  </div>
</template>

<script>
import { getEnWechatConfig, getEnWechatAgentConfig , getExternalApprovalData} from '@/api/enwechat';

export default {
  name: 'externalControl',
  data() {
    return {
      loading: false,
      params: {search: '', page: 1, page_size: 15,},
      pagination: {total:0, current: 1, pageSize: 15,},
      columns: [],
      tableData: [],
      selectedRowKeys: [],
      control_key: '',
    };
  },
  created() {
    this.control_key = this.$route.query.key;

    this.initializeJwxwork();

    this.getTableList();
  },
  computed: {
    rowSelection() {
      const { selectedRowKeys } = this;
      return {
        selectedRowKeys,
        onChange: this.onSelectChange,
        type: this.$route.query.selectorType === 'single' ? 'radio' : 'checkbox',
        onSelection: this.onSelection,
      };
    },
  },
  methods: {
    initializeJwxwork() {
      getEnWechatConfig()
          .then(response => {
            wx.config({
              beta: true,
              debug: false,
              appId: response.appId,
              timestamp: response.timestamp,
              nonceStr: response.nonceStr,
              signature: response.signature,
              jsApiList: ['saveApprovalSelectedItems'],
            });

            wx.ready(() => {
              console.log('企业微信 JSSDK 配置成功');
              getEnWechatAgentConfig()
                  .then(response => {
                    console.log(response)
                    wx.agentConfig({
                      corpid: response.appId,
                      agentid: response.agentid,
                      timestamp: response.timestamp,
                      nonceStr: response.nonceStr,
                      signature: response.signature,
                      jsApiList: ['saveApprovalSelectedItems'],
                      success: function (res) {
                        console.log(res)
                      },
                      fail: function (res) {
                        if (res.errMsg.indexOf('function not exist') > -1) {
                          alert('版本过低请升级')
                        }
                      }
                    });
                  })
            });

            wx.error(err => {
              console.log(err);
            });
          })
          .catch(error => {
            console.log(error);
          });
    },
    getTableList() {
      this.loading = true;
      this.params.api_name =  this.$route.query.api_name
      if (!this.params.api_name){
        return null
      }
      getExternalApprovalData(this.params) // 调用传递的函数，传递参数
          .then(res => {
            const { results = {}, count = 0 } = res;
            const data = results.map(item => {
              const { id, name, ...rest } = item;
              return { key: id, value: name, ...rest };
            });

            if (data.length < 1) {
              return null;
            }

            // 创建表头
            const firstItem = data[0];
            this.columns = Object.keys(firstItem).map(key => ({
              title: key,
              dataIndex: key,
            }));

            this.tableData = data;
            this.pagination.total = count;
          })
          .catch(() => {
            this.$message.error('数据获取失败');
          })
          .finally(() => {
            this.loading = false;
          });
    },
    handleTableChange(pagination, filters, sorter) {
      this.pagination.current = pagination.current;
      this.params.page = pagination.current;
      this.params.page_size = pagination.pageSize;
      this.getTableList();
    },
    search() {
      this.pagination.current = 1;
      this.params.page = 1;
      this.params.page_size = null;
      this.getTableList();
      // 删除查询参数
      delete this.params.name;
    },
    handleClear() {
      this.getTableList()
    },
    onSelectChange(selectedRowKeys) {
    //   console.log('selectedRowKeys----------', selectedRowKeys);
    //   this.selectedRowKeys = selectedRowKeys;
    },
    onSelection(selectedRowKeys, selectedRows) {
      console.log('Selected Row Keys:', selectedRowKeys);
      console.log('Selected Rows:', selectedRows);
    },
    saveSingle() {
      console.log('单选保存')
      wx.invoke('saveApprovalSelectedItems', {
        "key": this.control_key,
        "selectedData": JSON.stringify({ key: 'computer', value: '电脑' })
      }, (res) => {
        if (res.err_msg === 'saveApprovalSelectedItems:ok') {
          console.log('保存成功');
        } else {
          console.error('保存失败', res);
        }
      });
    },
  }
};
</script>

<style scoped>
</style>
